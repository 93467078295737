import cx from 'classnames';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import { Payment } from 'api/types';
import { ellipsize, formatSum } from '#util';
import { ErrorView, LoadIndicator } from '#components';
import {
  PaymentDetailsContextProvider,
  usePaymentDetailsContext,
} from './Context';
import { PaymentStatus } from '@zimbro-app/enums';
import { SafeNumber } from '@zimbro-app/util';

const Copyable: FC<
  PropsWithChildren<{
    title: string;
    value: string;
    className?: string;
    release?: number;
  }>
> = ({ children, value, className, title, release = 2500 }) => {
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    if (!clicked) {
      return;
    }
    const timeout = setTimeout(() => setClicked(false), release);
    return () => clearTimeout(timeout);
  }, [clicked]);

  return (
    <a
      href='#'
      title={title}
      className={cx(
        'uk-flex',
        'uk-flex-middle',
        'uk-flex-center',
        'uk-link-toggle',
        className,
      )}
      onClick={e => (
        e.preventDefault(),
        e.stopPropagation(),
        navigator.clipboard.writeText(value),
        setClicked(true)
      )}
    >
      {children}
      <span
        className={cx('uk-margin-small-left', 'uk-link-muted', 'uk-icon-link')}
        data-uk-icon={clicked ? 'check' : 'copy'}
        onClick={e => e.preventDefault()}
      />
    </a>
  );
};

const PaymentProcessing: FC<{
  onReturn: string;
  className?: string;
}> = ({ onReturn, className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx('uk-flex', 'uk-flex-column', 'uk-flex-middle', className)}
    >
      <div>
        <h2 className={cx('uk-text-large', 'uk-text-center')}>
          {t('payment/method/canceled/title', 'Payment processing')}
        </h2>
        <p
          className={cx(
            'uk-text-small',
            'uk-margin-medium-bottom',
            'uk-text-center',
            'uk-text-muted',
          )}
        >
          {t(
            'payment/method/canceled/notification',
            'Your transaction is detected and waiting to be confirmed by the blockchain. You may return to the store website or wait for the further status updates.',
          )}
        </p>
      </div>
      <a
        href={onReturn}
        className={cx('uk-button', 'uk-button-secondary', 'uk-border-pill')}
      >
        {t('payment/method/button/back', 'Back to Store')}
      </a>
    </div>
  );
};

const PaymentRequired: FC<{ paymentAmount: SafeNumber }> = ({
  paymentAmount,
}) => {
  const { t } = useTranslation();
  const { isLoading, payment, paymentUrl, rollback, cancel } =
    usePaymentDetailsContext();

  return (
    <>
      {payment.paymentStatus === PaymentStatus.Failed ? (
        <p className={cx('uk-text-danger', 'uk-text-small', 'uk-text-center')}>
          {t(
            'payment.check-out.failed',
            'Your payment got declined by the blockchain, ' +
              'please check the transaction in your wallet and try again. ' +
              'Payment status is automatically updated every 30 sec.',
          )}
        </p>
      ) : (
        <p className={cx('uk-text-muted', 'uk-text-small', 'uk-text-center')}>
          {t(
            'payment.check-out.instruction',
            'To complete your order please make the payment, ' +
              'or scan the QR-code with your wallet app. ' +
              'Payment status is automatically updated every 30 sec.',
          )}
        </p>
      )}
      <Copyable
        title={t('payment.details.address.copy', 'Copy payment address')}
        value={payment.wallet}
        className={cx('uk-margin-small-bottom')}
      >
        <span className={cx('uk-text-large')}>
          {ellipsize.mid(payment.wallet, 24)}
        </span>
      </Copyable>
      <Copyable
        title={t('payment.details.address.copy', 'Copy payment amount')}
        value={paymentAmount.format.toString()}
        className={cx('uk-margin-bottom')}
      >
        <span className={cx('uk-text-large')}>
          {formatSum(paymentAmount.format, {
            decimals: Math.min(paymentAmount.decimals, 8),
          })}
          &nbsp;{payment.paymentToken}
        </span>
      </Copyable>
      <div
        style={{ height: '256px' }}
        className={cx(
          'uk-flex',
          'uk-flex-middle',
          'uk-flex-center',
          'uk-margin-top',
          'uk-margin-bottom',
        )}
      >
        {isLoading ? (
          <LoadIndicator ratio={4} />
        ) : (
          <QRCodeSVG size={256} value={paymentUrl} fgColor='#193952' />
        )}
      </div>
      <div className={cx('uk-flex', 'uk-flex-column', 'uk-margin-medium-top')}>
        {payment.paymentStatus !== PaymentStatus.Pending && (
          <div
            className={cx('uk-flex', 'uk-flex-around', 'uk-child-width-1-2')}
          >
            <a
              href='#'
              onClick={e => (e.preventDefault(), rollback())}
              className={cx(
                'uk-button',
                'uk-button-default',
                'uk-border-pill',
                'uk-margin-small-right',
              )}
            >
              {t('payment/method/button/back', 'Change Method')}
            </a>
            <a
              href='#'
              onClick={e => (e.preventDefault(), cancel())}
              className={cx('uk-button', 'uk-button-danger', 'uk-border-pill')}
            >
              {t('Cancel Payment')}
            </a>
          </div>
        )}
        <div>
          <a
            href='#'
            onClick={e => (e.preventDefault(), rollback())}
            className={cx(
              'uk-button',
              'uk-button-default',
              'uk-border-pill',
              'uk-margin-top',
              'uk-width-1-1',
            )}
          >
            {t('payment/method/button/back', 'Back to store')}
          </a>
        </div>
      </div>
    </>
  );
};

const PaymentMethod = () => {
  const { payment, error } = usePaymentDetailsContext();

  return (
    <div className={cx('uk-flex', 'uk-flex-column', 'uk-flex-center')}>
      {error || !payment || !payment.paymentAmount ? (
        <ErrorView error={error || new Error('Invalid payment')} />
      ) : payment.paymentStatus === PaymentStatus.Pending ? (
        <PaymentProcessing onReturn={payment.onReturn} />
      ) : (
        <PaymentRequired paymentAmount={payment.paymentAmount} />
      )}
    </div>
  );
};

export default (props: { payment: Payment }) => (
  <PaymentDetailsContextProvider {...props}>
    <PaymentMethod />
  </PaymentDetailsContextProvider>
);
